import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      maxWidth: "960px",
      width: "100%",
      marginTop: theme.spacing(11),
      marginBottom: theme.spacing(20),
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(4),
      },
    },
    titleContainer: {
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        textAlign: "left",
        alignItems: "flex-start",
      },
    },
    subtitle: {
      marginTop: theme.spacing(2),
    },
    textContainer: {
      marginTop: theme.spacing(6),
      "& > span": {
        display: "block",
        margin: theme.spacing(4, 0),
      },
      [theme.breakpoints.down("xs")]: {
        "& > span": {
          margin: theme.spacing(3, 0),
        },
      },
    },
    logo: {
      width: "160px",
      height: "160px",
      marginTop: theme.spacing(4),
    },
    certificate: {
      marginTop: theme.spacing(10),
    },
  });
