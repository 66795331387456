// Pages cannot be exported using `export { default } from "..."`
// shorthand due to Gatsby bug https://github.com/gatsbyjs/gatsby/issues/12384

import QualityPolicyPage from "@src/templates/QualityPolicyPage";
export default QualityPolicyPage;

import { graphql } from "gatsby";

export const iso = graphql`
  fragment iso on File {
    childImageSharp {
      fluid(maxWidth: 160) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const certificateIso = graphql`
  fragment certificateIso on File {
    childImageSharp {
      fluid(maxWidth: 880) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const pageQuery = graphql`
  query QualityPolicyPageQuery {
    iso: file(relativePath: { eq: "images/iso.png" }) {
      ...iso
    }
    certificateIsoEn: file(
      relativePath: { eq: "images/certificate_iso_en.png" }
    ) {
      ...certificateIso
    }
    certificateIsoHe: file(
      relativePath: { eq: "images/certificate_iso_he.png" }
    ) {
      ...certificateIso
    }
  }
`;
