import { Typography, WithStyles, withStyles } from "@material-ui/core";
import React from "react";
import { T } from "react-targem";

import { styles } from "./PrinciplesList.styles";

export interface PrinciplesListProps extends WithStyles<typeof styles> {}

class PrinciplesList extends React.PureComponent<PrinciplesListProps> {
  public render() {
    const { classes } = this.props;
    return (
      <>
        <Typography variant="body1">
          <T message="Trucknet's management and employees are committed to the following principles: " />
        </Typography>
        <Typography
          variant="body1"
          component="ul"
          className={classes.principlesContainer}>
          <span className={classes.listItem}>
            <T message="Manage interfaces for communication among all parties with full transparency and mutual respect." />
          </span>
          <span className={classes.listItem}>
            <T message="Aim to understand our customers' needs and requirements and continuously strive to fulfill them." />
          </span>
          <span className={classes.listItem}>
            <T message="Manage the company while serving as a role model and setting business and quality-service goals, as well as preparing plans to achieve these objectives. Work according to planned, documented and controlled work processes and procedures." />
          </span>
          <span className={classes.listItem}>
            <T message="Create for employees a work environment that encourages innovation,  creativity, and motivation to reach the highest professional levels." />
          </span>
          <span className={classes.listItem}>
            <T message="Work in compliance with defined responsibilities and company guidelines and full cooperation and respect for one another." />
          </span>
        </Typography>
      </>
    );
  }
}

export default withStyles(styles)(PrinciplesList);
