import {
  Container,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import GatsbyImage from "gatsby-image";
import React from "react";
import { T, WithLocale, withLocale } from "react-targem";

import { withPageData, WithPageData } from "@src/contexts/PageDataContext";
import { QualityPolicyPageProps } from "./QualityPolicyPage";
import { styles } from "./QualityPolicyTemplate.styles";

import PrinciplesList from "./PrinciplesList";

export type QualityPolicyTemplateProps = WithStyles<typeof styles> &
  WithLocale &
  WithPageData<QualityPolicyPageProps> & {};

class QualityPolicyTemplate extends React.Component<
  QualityPolicyTemplateProps
> {
  public render() {
    const { classes, pageData, locale } = this.props;
    const { certificateIsoEn, certificateIsoHe, iso } = pageData;

    return (
      <Container className={classes.container}>
        <div className={classes.titleContainer}>
          <Typography variant="h2">
            <T message="Quality management policy" />
          </Typography>
          <Typography variant="h5" className={classes.subtitle}>
            <T message="Trucknet is ISO 9001 certified company" />
          </Typography>
          <GatsbyImage
            className={classes.logo}
            fluid={iso.childImageSharp.fluid}
          />
        </div>
        <Typography variant="body1" className={classes.textContainer}>
          <span>
            <T message="Trucknet Enterprise LTD, the management and employees, is fully committed to ensure and maintain a high level of service to all its customers and to provide a high-quality product that meets the needs and requirements of the customer." />{" "}
            <br />
            <T message="Trucknet's management team will work to ensure that its business activity, for intelligent transportation services for optimization, shall comply with relevant industry standards." />{" "}
          </span>
          <span>
            <T message="The company is committed to implementing the ISO 9001 quality standard requirements and all applicable regulatory requirements related to its products and services.  " />{" "}
          </span>
          <span>
            <T message="The company's management will allocate all necessary resources to guarantee quality assurance in all main business areas. This includes continuous improvement of the quality management system by carrying out customer satisfaction surveys, periodic internal quality audits, risk assessment, and more.  " />{" "}
          </span>
        </Typography>
        <PrinciplesList />
        <GatsbyImage
          className={classes.certificate}
          fluid={
            locale === "he"
              ? certificateIsoHe.childImageSharp.fluid
              : certificateIsoEn.childImageSharp.fluid
          }
        />
      </Container>
    );
  }
}

export default withPageData(
  withLocale(withStyles(styles)(QualityPolicyTemplate)),
);
