import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    principlesContainer: {
      marginTop: theme.spacing(2),
      paddingLeft: 0,
    },
    listItem: {
      display: "block",
      margin: theme.spacing(1, 0, 1, 2.5),
      position: "relative",
      "&:before": {
        content: `"•"`,
        color: theme.palette.text.primary,
        position: "absolute",
        left: theme.spacing(-2.5),
      },
    },
  });
