import React from "react";

import SeoHead from "@src/components/SeoHead";
import IndexLayout from "@src/layouts/IndexLayout";
import { PageWithImagesProps } from "@src/types";
import { t } from "@src/utils/translations";

import QualityPolicyTemplate from "./QualityPolicyTemplate";

export interface QualityPolicyPageProps
  extends PageWithImagesProps<
    "iso" | "certificateIsoEn" | "certificateIsoHe"
  > {}

class QualityPolicyPage extends React.Component<QualityPolicyPageProps> {
  public render() {
    return (
      <IndexLayout {...this.props}>
        <SeoHead
          title={t("Quality Management Policy")}
          description={t(
            "Trucknet Enterprise LTD, management and employees are fully committed to ensure and maintain a high level of service to all Trucknet’s customers and to provide a high-quality product that meets the customer's requirements and needs.",
          )}
          keywords={[
            t("quality"),
            t("quality policy"),
            t("certified company"),
            t("iso"),
          ]}
        />
        <QualityPolicyTemplate />
      </IndexLayout>
    );
  }
}

export default QualityPolicyPage;
